import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
  HttpHandler,
  HttpEvent,
  HttpErrorResponse,
  HttpEventType
} from '@angular/common/http';

import { Observable, throwError,Subject, BehaviorSubject } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { LoaderServiceService } from './loader-service.service';
import Swal from 'sweetalert2';



@Injectable({
  providedIn: 'root'
})
export class HTTPStatus {
  private requestInFlight$: BehaviorSubject<any>;
  constructor() {
    this.requestInFlight$ = new BehaviorSubject(false);
  }

  setHttpStatus(inFlight: boolean) {
    this.requestInFlight$.next(inFlight);
  }


  getHttpStatus(): Observable<boolean> {
    return this.requestInFlight$.asObservable();
  }
}

@Injectable({
  providedIn: 'root'
})
export class HTTPStatus2 {
    private requestInFlight$: BehaviorSubject<number>;
    constructor() {
      this.requestInFlight$ = new BehaviorSubject(0);
    }

    setProgress(inFlight: number) {
      this.requestInFlight$.next(inFlight);
    }
    getHttpStatus(): Observable<number> {
      return this.requestInFlight$.asObservable();
    }
}
 
@Injectable({
  providedIn: 'root'
})
export class HttpinterceptService implements HttpInterceptor {

  constructor(private loader:LoaderServiceService,private status: HTTPStatus,private status2: HTTPStatus2) { }

  // private loaderSubject = new Subject<Boolean>();
  // loaderState = this.loaderSubject.asObservable();
  
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>{
    //this.loader.show(); 
    // console.log(req);
    // if(req.url=="http://localhost:4200/file/image")
    // {
    //   return next.handle(req);
    // }
    this.status.setHttpStatus(true);
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        // switch (event.type) {
        //   case HttpEventType.UploadProgress:
        //      var progress = Math.round(event.loaded / event.total * 100);
        //      this.status2.setProgress(progress);
        //    break;
        //   case HttpEventType.Response:{
        //       this.status.setHttpStatus(false);
        //       return event;
        //   }
        // }
          if (event instanceof HttpResponse) {
              console.log('event--->>>', event);
              
              this.status.setHttpStatus(false);
              
                return event;
          }
          // else if(event.type==HttpEventType.UploadProgress) 
          // {
          //   var progress = Math.round(event.loaded / event.total * 100);
          //   this.status2.setProgress(progress);
          // }
        //}
         
      },(err)=>{
        this.status.setHttpStatus(false);
        Swal.fire('','Something went wrong','error');
      }));

  }
}

export interface LoaderState {
  show: boolean;
}