import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import {  routes } from '../../models/login';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    dropdown: boolean;
  item: Array<RouteInfo>
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;
  public isCollapsed1 = true;
  public isCollapsed2 = true;
  public isCollapsed3 = true;
  username: string;
  profile_pic: string;
  islogin: boolean; 

  constructor(private router: Router) { }

  tabindex;
  tabindex2;

  change(path,i)
  {
    console.log(path);
    this.tabindex=i;
        this.router.navigate([path]);
  }

  title;
  change2(path,i,tile)
  {
    console.log(path);
    this.title=tile;
    this.tabindex2=i;
        this.router.navigate([path]);
  }

  get(x)
  {
    console.log(x);
   // return (this.tabindex2==x && t==this.title);
  }
  ngOnInit() {
    
    this.username = localStorage.getItem('username');
    this.profile_pic = localStorage.getItem('profile_pic');
    // let rout = JSON.parse(routes.RouteInfo)
    let rout = JSON.parse(window.localStorage.getItem('navigation'))
    this.menuItems = rout 
    
    if(localStorage.getItem("email")=="support@edterra.com"){
                //var item=this.menuItems.splice(4,1);
                // this.menuItems[7].item.push(item[0]);
                // this.menuItems[this.menuItems.length-1].title="Download";
                this.menuItems.push({class: "fas fa-tasks text-theme",
                dropdown: false,
                icon: "",
                item: [],
                path: "/getstudentbadges",
                title: "Badges Center"});
                this.menuItems.push({class: "fas fa-tasks text-theme",
                dropdown: false,
                icon: "",
                item: [],
                path: "/getfeedbackdetails",
                title: "Feedback center"});
                this.menuItems.push({class: "fas fa-tasks text-theme", dropdown: false, icon: "", item:[], path: "/motivationalmsg",title:"Motivational Msg center"}
                );    
                this.menuItems[5]['path']="/certificatesnew";
    }
    // this.menuItems.push({class: "fas fa-tasks text-theme",
    // dropdown: false,
    // icon: "",
    // item: [],
    // path: "/certificatesnew",
    // title: "CertificateNew"});
    
    
    console.log(this.menuItems); 
    // this.menuItems = ROUTES.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
      this.isCollapsed1 = true;
      this.isCollapsed2 = true;
   });
  }

  // get(i)
  // {

  // }
  get profile_pi(): any {
    // let user1 = JSON.parse(localStorage.getItem('userdata'));
    // if(!user1){
    //   localStorage.removeItem('userdata');
    //   this.router.navigate(['/login']);
    // }
    let user = localStorage.getItem('username');
    if (!user) {
      this.islogin = false
    } else {
      this.islogin = true
    }
    let profile_pic = localStorage.getItem('profile_pic');
    if (!profile_pic) {
      return "assets/img/theme/team-4-800x800.jpg"
    } else {
      return profile_pic;
    }
  }
  logOut() {
    localStorage.removeItem('user_id');
    localStorage.removeItem('username');
    localStorage.removeItem('profile_pic');
    localStorage.removeItem('token');
    this.router.navigate(['/login']);
  }

}
