import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';

import { AppComponent,SafePipe } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { MasterDataComponent } from './layouts/master-data/master-data.component';
import { UserComponent } from './layouts/user/user.component';

import { NgbModule, NgbProgressbarModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { LoadingScreenInterceptor } from './loading.interceptor';
import { environment } from '../environments/environment';
import { DataTablesModule } from 'angular-datatables';
import { StudentbadgedetailComponent } from './pages/studentbadgedetail/studentbadgedetail.component';
import { StudentfeedbackdetailComponent } from './pages/studentfeedbackdetail/studentfeedbackdetail.component';
import { Certificates2Component } from './pages/certificates2/certificates2.component';
import { EditcertComponent } from './pages/certificates2/editcert/editcert.component';
import { ViewcourseComponent } from './pages/course/viewcourse/viewcourse.component';
import { NgxLoadingModule } from 'ngx-loading';
import { FormsModule } from '@angular/forms';
import { MotivationalmsgComponent } from './pages/motivationalmsg/motivationalmsg.component';
import { EditmotivationalmsgComponent } from './pages/motivationalmsg/editmotivationalmsg/editmotivationalmsg.component';
import { AddmotivationalmsgComponent } from './pages/motivationalmsg/addmotivationalmsg/addmotivationalmsg.component';
import { HttpinterceptService } from './services/httpintercept.service';
import { LoaderServiceService } from './services/loader-service.service';
const config: SocketIoConfig = { url: environment.Api_url, options: {} };
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
// import { NgxCaptchaModule } from 'ngx-captcha';
// import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@NgModule({
  imports: [
    // NgxCaptchaModule,
    // RecaptchaModule,  //this is the recaptcha main module
    // RecaptchaFormsModule, //this is the module for form incase form validation
    BrowserAnimationsModule,
    HttpClientModule,
    ComponentsModule,
    NgbModule,
    RouterModule,
    AppRoutingModule,
    SocketIoModule.forRoot(config),
    DataTablesModule,
    NgxLoadingModule.forRoot({
      // backdropBackgroundColour: 'rgba(0,0,0,0.1)',
      // backdropBorderRadius: '4px',
      // primaryColour: '#ffffff',
      // secondaryColour: '#ffffff',
      // tertiaryColour: '#ffffff'
    }),
    FormsModule,
    NgbProgressbarModule,
  ],
  declarations: [
    AppComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
	  MasterDataComponent,
    UserComponent,
    SafePipe,
    
   // Certificates2Component,
   // EditcertComponent,
   // StudentfeedbackdetailComponent
    //StudentbadgedetailComponent
  ],
  providers: [
    {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpinterceptService,
    multi: true
  },
  LoaderServiceService
],
  bootstrap: [AppComponent]
})
export class AppModule { }
