import { Component, AfterViewInit, ChangeDetectorRef} from '@angular/core';
import {  Router, NavigationStart, NavigationCancel, NavigationEnd } from '@angular/router';
import {  ViewEncapsulation, ViewChild, ElementRef, PipeTransform, Pipe, OnInit } from '@angular/core';
import { DomSanitizer } from "@angular/platform-browser";
import { HttpinterceptService, HTTPStatus, HTTPStatus2 } from './services/httpintercept.service';
import { LoaderServiceService } from './services/loader-service.service';
import { Subject, Observable } from 'rxjs';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse } from '@angular/common/http';
import { map } from 'rxjs/operators';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) { }
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit,OnInit {
  title = 'Edterra Admin';
  loading;
  loading2=false;//Subject<boolean> = this.loaderService.isLoading;//false;
  loading3=false;
  
  progress;

  ngOnInit(): void {
    //Called after the constructor, initializing input properties, and the first call to ngOnChanges.
    //Add 'implements OnInit' to the class.
    // this.service.loaderState.subscribe((s:boolean)=>{
    //   alert(s);
    //   this.loading2=s;
    // });
    
  }
  showprogress(val)
  {
    //this.progress=val;
    //this.loading3=true;
  }
  hideprogress()
  {
    //this.loading3=false;
  }

  constructor(
    private router: Router,
    private service:HttpinterceptService,
    private loaderService:LoaderServiceService,
    private ref:ChangeDetectorRef,
    private httpStatus: HTTPStatus,

    private httpStatus2: HTTPStatus2
  ) {
    this.loading = true;
    this.httpStatus.getHttpStatus().subscribe((status: boolean) => {this.loading2 = status;
      if(status==false)
      {
        this.loading3=false;
      }
      console.log(status)});
      this.httpStatus2.getHttpStatus().subscribe((status: number) => {
        if(status != 0){
         this.loading3=true;
        }
        this.progress=status;
        console.log(this.loading3);
        console.log(status)});
  }
  ngAfterViewInit() {
    this.ref.detectChanges();
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationStart) {
          this.loading = true;
        }
        else if (
          event instanceof NavigationEnd ||
          event instanceof NavigationCancel
        ) {
          this.loading = false;
        }
      });
  }
  loadingTrue()
  {
    //this.loading2=true;
  }
  loadingFalse()
  {
    this.loading2=false;
  }
  onDeactivate() {
    // document.body.scrollTop = 0;
    // Alternatively, you can scroll to top by using this other call:
    window.scrollTo(0, 0)
  }
}
